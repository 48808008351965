<template>
  <div class="dashboard-container">
    <img style="position:absolute;bottom:0px;backgroud-color:#F2F5F5;opacity:0.6;" src="@/assets/dashboard-bg.svg">
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {};
  },
  computed: {},
  created() {}
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-container {
  width: 100%;
  height: 100%;
  .welcome {
  }
}
</style>
